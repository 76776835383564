import React, { memo, useRef, useCallback, useContext, useMemo, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import type { WrappedComponentProps } from 'react-intl-next';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl-next';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import EditIcon from '@atlaskit/icon/core/migration/edit';
import Button from '@atlaskit/button/custom-theme-button';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import { Box, Text } from '@atlaskit/primitives';
import Popup from '@atlaskit/popup';
import Spinner from '@atlaskit/spinner/spinner';

import { fg } from '@confluence/feature-gating';
import { EditPopupEligibleMigration } from '@confluence/fabric-page-optin-migration';
import {
	VIEW_PAGE_EDIT_BUTTON_EXPERIENCE,
	ExperienceSuccess,
} from '@confluence/experience-tracker';
import type { Route } from '@confluence/route';
import {
	RoutesContext,
	useNavigationPolicy,
	getSpaTransitionHref,
} from '@confluence/route-manager';
import {
	EDIT_PAGE,
	EDIT_BLOG,
	EDIT_PAGE_V2,
	EDIT_BLOG_V2,
	EDIT_PAGE_EMBED,
	SPACE_OVERVIEW,
	COMPANY_HUB_EDIT,
} from '@confluence/named-routes';
import { GeneralShortcutListener, ShortcutVisualizer, EDIT_SHORTCUT } from '@confluence/shortcuts';
import { END } from '@confluence/navdex';
import {
	EDIT_ROUTE_CHANGE_ACTION,
	EDIT_INTENT_EDIT_TTI_ACTION,
	EDIT_INTENT_EDIT_FMP_ACTION,
	VIEW_EDIT_BUTTON_CLICKED_ACTION,
} from '@confluence/browser-metrics';
import { ExperienceActionMeasures } from '@confluence/action-measures';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';
import { useEditPageLoadingActions } from '@confluence/load-edit-page/entry-points/EditPageLoadingContext';
import { useEditSource } from '@confluence/load-edit-page/entry-points/useEditSource';
import { useSearchSessionId } from '@confluence/search-session';
import { rememberScrolledPosition } from '@confluence/editor-scroll-position/entry-points/rememberScrolledPosition';
import {
	PAGE_EDIT_BUTTON_CLICK,
	BLOG_EDIT_BUTTON_CLICK,
	SPACE_EDIT_BUTTON_CLICK,
	PAGE_EDIT_SHORTCUT_E,
	BLOG_EDIT_SHORTCUT_E,
	SPACE_EDIT_SHORTCUT_E,
} from '@confluence/load-edit-page/entry-points/constants';
import { useHardStorageEnforcement } from '@confluence/storage-enforcement/entry-points/HardEnforcement/useHardStorageEnforcement';
import { expValEquals, UNSAFE_noExposureExp } from '@confluence/feature-experiments';

import { EditContentButtonPreloader } from './EditContentButtonPreloader';
import { useFabricEligibilityMigrationQuery } from './useFabricEligibilityMigrationQuery';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledButton = styled(Button)<{ isCompanyHubButton?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	borderRadius: (p) => (p.isCompanyHubButton ? '100% !important' : 0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	padding: (p) => (p.isCompanyHubButton ? '8px 6px !important' : undefined),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	height: (p) => (p.isCompanyHubButton ? 'auto !important' : undefined),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		minWidth: '30px',
	},
});

const i18n = defineMessages({
	editLabel: {
		id: 'edit-button.edit.label',
		defaultMessage: 'Edit this content',
		description: 'aria-label for the edit button',
	},
	editEmbedLabel: {
		id: 'edit-button.edit.embed.label',
		defaultMessage: 'Edit this embed',
		description: 'aria-label for the edit button for an embedded link',
	},
	editText: {
		id: 'edit-button.edit.text',
		defaultMessage: 'Edit',
		description: 'edit text for edit button',
	},
});

type EditContentButtonComponentProps = {
	contentId: string;
	contentType?: string;
	isEmbeddedContent?: boolean;
	isFabricSupported?: boolean;
	isCompanyHubButton?: boolean;
	onRender?: () => null;
	spaceKey?: string;
	migrateEPData?: (onSuccess?: (data?: any) => void, onFailure?: (err?: any) => void) => void;
};

const noop = () => null;

const EditContentButtonComponentInner: React.FC<
	EditContentButtonComponentProps & WrappedComponentProps
> = memo(
	({
		contentId,
		contentType,
		intl,
		isEmbeddedContent = false,
		isFabricSupported,
		onRender = noop,
		spaceKey,
		migrateEPData,
		isCompanyHubButton = false,
	}) => {
		const editButtonRef = useRef<HTMLElement>(null);
		const [localEditSource, setLocalEditSource] = useState('');
		const [isPopupVisible, setIsPopupVisible] = useState(false); // State to control popup visibility
		const { loadEditor } = useEditPageLoadingActions();
		const [, { setEditSource }] = useEditSource();

		const routesContext = useContext(RoutesContext);
		const [{ searchSessionId, additionalAnalytics }] = useSearchSessionId();
		const { match } = routesContext;
		const { routePolicy } = useNavigationPolicy();
		// Storage Enforcement (The query is preloaded) - #cc-onboarding
		const { enforceStorageLimit, shouldBlockCreate } = useHardStorageEnforcement({
			source: 'page-tree-quick-action-edit',
		});
		const { contentHash, fabricEditorEligibility, isFabricEligibilityLoading } =
			useFabricEligibilityMigrationQuery({ contentId, isFabricSupported });

		const currentRouteName = match?.name;
		const isBlogpost = contentType === 'blogpost';
		const linkParams = useMemo<{
			contentId: string;
			spaceKey: string | undefined;
			contentType: string;
		}>(
			() => ({
				contentId,
				spaceKey,
				contentType: isBlogpost ? 'blog' : 'pages',
			}),
			[contentId, spaceKey, isBlogpost],
		);
		const dataTestId = isFabricSupported ? 'fabric-edit-button' : undefined;

		let routeType: Route;

		// Currently a temporary check for blogs is added here until Embedded Pages supports blogs.
		if (isEmbeddedContent && !isBlogpost) {
			routeType = EDIT_PAGE_EMBED;
		} else if (isCompanyHubButton) {
			routeType = COMPANY_HUB_EDIT;
		} else if (isFabricSupported) {
			routeType = isBlogpost ? EDIT_BLOG_V2 : EDIT_PAGE_V2;
		} else {
			routeType = isBlogpost ? EDIT_BLOG : EDIT_PAGE;
		}

		useEffect(() => {
			let entryPoint = PAGE_EDIT_BUTTON_CLICK;

			if (isBlogpost) {
				entryPoint = BLOG_EDIT_BUTTON_CLICK;
			}

			const isSpaceOverview = currentRouteName === SPACE_OVERVIEW.name;

			if (isSpaceOverview) {
				entryPoint = SPACE_EDIT_BUTTON_CLICK;
			}

			setEditSource(entryPoint);
			setLocalEditSource(entryPoint);
		}, [isBlogpost, setEditSource, currentRouteName, setLocalEditSource]);

		const clickEditPencilButton = useCallback(
			() => editButtonRef && (ReactDOM.findDOMNode(editButtonRef.current) as HTMLElement)?.click(),
			[],
		);

		const measureEditButtonClickConversion = useCallback(
			(isClick: boolean) => {
				if (!isClick) {
					//shortcut
					let entryPoint = PAGE_EDIT_SHORTCUT_E;

					if (isBlogpost) {
						entryPoint = BLOG_EDIT_SHORTCUT_E;
					}

					const isSpaceOverview = currentRouteName === SPACE_OVERVIEW.name;

					if (isSpaceOverview) {
						entryPoint = SPACE_EDIT_SHORTCUT_E;
					}

					setEditSource(entryPoint);
					setLocalEditSource(entryPoint);
				}

				ExperienceActionMeasures.markMeasureStart(EDIT_INTENT_EDIT_FMP_ACTION);
				ExperienceActionMeasures.markMeasureStart(EDIT_INTENT_EDIT_TTI_ACTION);

				ExperienceActionMeasures.markMeasureEnd(VIEW_EDIT_BUTTON_CLICKED_ACTION);

				ExperienceActionMeasures.markMeasureStart(EDIT_ROUTE_CHANGE_ACTION);
			},
			[isBlogpost, currentRouteName, setEditSource, setLocalEditSource],
		);

		const onClickEdit = useCallback(
			(e: any) => {
				measureEditButtonClickConversion(e.isTrusted);
				rememberScrolledPosition(contentId);

				const spaTransitionHref = getSpaTransitionHref(e, routesContext);
				const routePolicyPushInNewTab =
					routePolicy &&
					!routePolicy.willPushInSelf(currentRouteName, contentId, routeType.name, contentId);

				if (!isBlogpost && Boolean(migrateEPData) && routePolicyPushInNewTab) {
					e.preventDefault();
					const navigateToEmbeddedEditor = () => {
						routesContext.push(EDIT_PAGE_EMBED.toUrl(linkParams));
					};
					const navigateToClassicEditor = () => {
						routesContext.push(routeType.toUrl(linkParams));
					};
					migrateEPData!(navigateToEmbeddedEditor, navigateToClassicEditor);
				}

				/**
				 * If we end up opening editor route in a new tab (regardless route policy specified it or user does cmd+click etc.),
				 * we don't need to show the editor loading component in the current tab and preload editor data.
				 * The opened new tab will load the editor data with the loading icon
				 * In this case, we fallback to `<Link>`'s `push` method by RouteManager
				 */
				if (spaTransitionHref && !routePolicyPushInNewTab) {
					e.preventDefault();
					e.stopPropagation();

					if (!e.isTrusted) {
						// Preload the editor route when user used the "e" shortcut, as otherwise
						// the standard preloading logic isn't triggered
						routesContext.preloadRoute(spaTransitionHref);
					}

					loadEditor({
						contentId,
						spaceKey,
						redirectUrl: spaTransitionHref,
						contentType,
						isFabricSupported,
						isEmbeddedContent,
					});
				}
			},
			[
				measureEditButtonClickConversion,
				contentId,
				routesContext,
				routePolicy,
				currentRouteName,
				routeType,
				isBlogpost,
				migrateEPData,
				linkParams,
				loadEditor,
				spaceKey,
				contentType,
				isFabricSupported,
				isEmbeddedContent,
			],
		);

		const isOnEditRoute = useIsEditorPage();

		const mode = match?.query?.mode;
		const fireClickAnalytics = useCallback(
			(e: any, analyticsEvent: any) => {
				analyticsEvent
					.update({
						data: {
							action: 'clicked',
							actionSubject: 'editButton',
							attributes: {
								componentVersion: 'v2',
								contentType,
								contentId,
								isFabricSupported,
								navdexPointType: END,
								searchSessionId,
								...additionalAnalytics,
								mode,
								isOnEditRoute,
							},
							source: e.isTrusted ? 'editContentButton' : 'editShortcut',
						},
						type: 'sendTrackEvent',
					})
					.fire();
			},
			[
				contentType,
				contentId,
				isFabricSupported,
				searchSessionId,
				mode,
				isOnEditRoute,
				additionalAnalytics,
			],
		);

		/**
		 * Cleanup with the platform_editor_on_edit_convert experiment.
		 */
		const [convertOnEditExpConfig] = UNSAFE_noExposureExp('platform_editor_on_edit_convert');
		const showConvertPagePopup =
			!isFabricSupported && fabricEditorEligibility === 'SUPPORTED' && contentHash;

		const handleButtonClick = useCallback(
			(e: React.MouseEvent, analyticsEvent: any) => {
				if (
					showConvertPagePopup &&
					expValEquals('platform_editor_on_edit_convert', 'isEnabled', true)
				) {
					e.preventDefault(); // Prevent default action
					setIsPopupVisible((prev) => !prev); // Toggle popup visibility
					fireClickAnalytics(e, analyticsEvent);
				} else {
					fireClickAnalytics(e, analyticsEvent);
					onClickEdit(e);
				}
			},
			[showConvertPagePopup, fireClickAnalytics, onClickEdit],
		);

		const button = (
			<Tooltip
				tag="span"
				content={
					<ShortcutVisualizer
						shortcut={EDIT_SHORTCUT}
						contentBefore={
							<FormattedMessage
								id="edit-button.tooltip"
								defaultMessage="Edit"
								description="tooltip for button to edit"
							/>
						}
					/>
				}
			>
				<StyledButton
					isCompanyHubButton={isCompanyHubButton}
					appearance="subtle"
					iconBefore={
						<EditIcon
							label=""
							color={token('color.icon.subtle')}
							spacing={fg('confluence_frontend_object_header') ? 'none' : 'spacious'}
						/>
					}
					ref={editButtonRef}
					onClick={enforceStorageLimit(handleButtonClick)}
					href={shouldBlockCreate ? undefined : routeType.toUrl(linkParams)}
					data-test-id={dataTestId}
					id="editPageLink"
					aria-label={intl.formatMessage(i18n.editLabel)}
				>
					{fg('confluence_frontend_object_header') && (
						<Text color="color.text.subtle" align="center" weight="medium">
							{intl.formatMessage(i18n.editText)}
						</Text>
					)}
				</StyledButton>
			</Tooltip>
		);

		if (isFabricEligibilityLoading) {
			return (
				<Spinner
					interactionName={
						fg('ufo-manual-experimental-holds-confluence')
							? 'edit-content-button-preloader'
							: undefined
					}
					size="small"
					testId="edit-content-button-preloader"
				/>
			);
		} else {
			return (
				<EditContentButtonPreloader
					key={contentId}
					contentId={contentId}
					spaceKey={spaceKey}
					isEmbeddedEditor={isEmbeddedContent}
					editSource={localEditSource}
				>
					<GeneralShortcutListener accelerator={EDIT_SHORTCUT} listener={clickEditPencilButton} />
					{onRender()}
					{showConvertPagePopup && convertOnEditExpConfig.get('isEnabled', false) && contentHash ? (
						<Popup
							isOpen={isPopupVisible}
							onClose={() => setIsPopupVisible(false)}
							placement="bottom-start"
							content={() => {
								return (
									<EditPopupEligibleMigration
										onClickEdit={onClickEdit}
										contentId={contentId}
										href={shouldBlockCreate ? undefined : routeType.toUrl(linkParams)}
										contentHash={contentHash}
										contentType={contentType}
										spaceKey={spaceKey}
									/>
								);
							}}
							offset={[-34, 8]}
							trigger={({ ref }) => <Box ref={ref}>{button}</Box>}
						/>
					) : (
						<Box>{button}</Box>
					)}
					<ExperienceSuccess name={VIEW_PAGE_EDIT_BUTTON_EXPERIENCE} />
				</EditContentButtonPreloader>
			);
		}
	},
);

export const EditContentButtonComponent = injectIntl(EditContentButtonComponentInner);

EditContentButtonComponent.propTypes = {
	contentId: PropTypes.string.isRequired,
	onRender: PropTypes.func,
	isCompanyHubButton: PropTypes.bool,
	isEmbeddedContent: PropTypes.bool,
	isFabricSupported: PropTypes.bool,
};
