import React, { useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { FormattedMessage, defineMessages } from 'react-intl-next';
import gql from 'graphql-tag';

import { token } from '@atlaskit/tokens';
import Button, { LinkButton } from '@atlaskit/button/new';
import { Box, xcss, Inline } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useSessionData } from '@confluence/session-data';
import { confluenceLocalStorageInstance, keys } from '@confluence/storage-manager';
import { EDIT_PAGE_V2, EDIT_BLOG_V2 } from '@confluence/named-routes';
import { useRouteActions } from '@confluence/route-manager';
import { markErrorAsHandled } from '@confluence/graphql';

import { getStatusFromResponse } from './util.js';

const i18n = defineMessages({
	migrationModalTitle: {
		id: 'fabric-page-optin-migration.migration-modal.title',
		defaultMessage: 'Get the latest editing experience',
		description: 'Title for migration modal',
	},
	migrationModalDescription: {
		id: 'fabric-page-optin-migration.migration-modal.description',
		defaultMessage: 'Select <b>Update page</b> to get the latest editor experience.',
		description: 'Description for migration modal',
	},
	migrationModalUpdateButton: {
		id: 'fabric-page-optin-migration.migration-modal.update-button',
		defaultMessage: 'Update page',
		description: 'Update button for migration modal',
	},
	migrationModalCancelButton: {
		id: 'fabric-page-optin-migration.migration-modal.cancel-button',
		defaultMessage: 'Not now',
		description: 'Cancel button for migration modal',
	},
	migrationModalUnsupported: {
		id: 'fabric-page-optin-migration.migration-modal.unsupported',
		defaultMessage: 'This page cannot be converted',
		description: 'Error for converting page',
	},
});

const EditPopupEligibleMigrationPropsMigrationMutation = gql`
	mutation EditPopupEligibleMigrationPropsMigrationMutation($contentId: ID!, $contentHash: String!)
	@experimental {
		experimentalMigratePages(contentId: $contentId, contentHash: $contentHash) {
			status
		}
	}
`;

const popupContainerStyle = xcss({
	paddingTop: 'space.200',
	paddingBottom: 'space.200',
	paddingLeft: 'space.300',
	paddingRight: 'space.300',
	fontSize: `${token('font.body.small', '16px')}`,
});

const topMarginStyle = xcss({
	marginTop: 'space.200',
});

const widthStyle = xcss({
	width: '297px',
});

type EditPopupEligibleMigrationProps = {
	onClickEdit: (e: React.MouseEvent<HTMLAnchorElement>) => void;
	contentId: string;
	href?: string;
	contentHash: string;
	contentType?: string;
	spaceKey?: string;
};

export const EditPopupEligibleMigration = ({
	onClickEdit,
	contentId,
	href,
	contentHash,
	contentType,
	spaceKey,
}: EditPopupEligibleMigrationProps) => {
	const [popupMessage, setPopupMessage] = React.useState({
		...i18n.migrationModalDescription,
	});
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [migratePages] = useMutation(EditPopupEligibleMigrationPropsMigrationMutation);

	const { push } = useRouteActions();

	const { isLoggedIn } = useSessionData();

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: 'eligibleMigrationModal',
			},
		}).fire();
	}, [createAnalyticsEvent]);

	const onClickConvert = React.useCallback(
		async (e) => {
			e.preventDefault();
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'eligibleMigrationModalConvertButton',
					source: 'editPopupEligibleMigration',
				},
			}).fire();
			const response = await migratePages({
				variables: {
					contentId,
					contentHash,
				},
			});
			const status = getStatusFromResponse(response);

			if (status === '200' || status === '204') {
				const redirectRoute =
					contentType === 'page'
						? EDIT_PAGE_V2.toUrl({
								contentType: 'pages',
								spaceKey,
								contentId,
							})
						: EDIT_BLOG_V2.toUrl({
								contentType: 'blog',
								spaceKey,
								contentId,
							});
				// perform redirect
				push(redirectRoute);
				confluenceLocalStorageInstance.setItem(keys.PAGE_JUST_CONVERTED, contentId);
			} else {
				setPopupMessage(i18n.migrationModalUnsupported);
				if (response && response.errors) {
					markErrorAsHandled(response.errors);
				}
			}
		},
		[createAnalyticsEvent, migratePages, contentId, contentHash, contentType, spaceKey, push],
	);
	if (!isLoggedIn || !href) {
		return null;
	}

	return (
		<Box xcss={popupContainerStyle}>
			<Box xcss={widthStyle}>
				<FormattedMessage tagName="h3" {...i18n.migrationModalTitle} />
				<Box xcss={topMarginStyle} role="status" aria-live="polite" aria-atomic="true">
					<FormattedMessage
						{...popupMessage}
						tagName="p"
						values={{
							b: (chunks) => <b>{chunks}</b>,
						}}
					/>
				</Box>
				<Box xcss={topMarginStyle}>
					<Inline space="space.200" alignBlock="center">
						<Button appearance="primary" onClick={onClickConvert}>
							<FormattedMessage {...i18n.migrationModalUpdateButton} />
						</Button>
						<LinkButton href={href} onClick={onClickEdit} appearance="subtle">
							<FormattedMessage {...i18n.migrationModalCancelButton} />
						</LinkButton>
					</Inline>
				</Box>
			</Box>
		</Box>
	);
};
